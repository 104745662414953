import logo from './images/logo.png'
import './App.css'
import { Routes, Route, Outlet, Link } from 'react-router-dom'

export default function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="privacy" element={<Privacy />} />

                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    )
}

function Layout() {
    return (
        <div>
            <Outlet />
        </div>
    )
}

function Home() {
    return (
        <div className="app">
            <div className="container">
                <img src={logo} className="logo" alt="logo" />
                <div className="logo-text">
                    Little Change - Helping out parents
                </div>
                <div className="link">
                    <Link to="privacy">Privacy Policy</Link>
                </div>
            </div>
        </div>
    )
}

function Privacy() {
    return (
        <div className="app">
            <div className="container">
                <div className="privacy-text">
                    <p>
                        Privacy Policy for Little Change Last Updated: 10th
                        November 2023
                    </p>
                    <p>
                        Thank you for using Little Change! This Privacy Policy
                        outlines how we collect, use, and safeguard your
                        information when you use our mobile application.
                    </p>
                    <p>Information We Collect</p>
                    <p>
                        1. Personal Information: We may collect personal
                        information that you provide voluntarily, such as your
                        name, email address, and other contact details when you
                        register or communicate with us.
                    </p>
                    <p>
                        2. Usage Data: We automatically collect information
                        about how you interact with our app, including your
                        device information, IP address, and usage patterns. This
                        data helps us improve our services and provide a better
                        user experience. How We Use Your Information 1. To
                        Provide and Maintain the App: We use your information to
                        deliver and maintain our app, authenticate you, and
                        ensure a consistent user experience. 2. To Improve Our
                        Services: We analyze usage data to understand how our
                        app is used, identify areas for improvement, and enhance
                        features.
                    </p>
                    <p>
                        3. To Communicate With You: We may use your contact
                        information to send you updates, newsletters, or respond
                        to your inquiries. Data Security We prioritize the
                        security of your information and employ
                        industry-standard measures to protect it from
                        unauthorized access, disclosure, alteration, and
                        destruction. Third-Party Services Our app may use
                        third-party services, and their use of your information
                        is governed by their respective privacy policies. We
                        encourage you to review the privacy policies of these
                        third-party services. Your Choices You can choose not to
                        provide certain information, but it may limit your
                        ability to use certain features of the app. You can also
                        opt-out of receiving promotional communications. Changes
                        to This Privacy Policy We may update our Privacy Policy
                        to reflect changes in our practices or for other
                        operational, legal, or regulatory reasons. We will
                        notify you of any changes by posting the new Privacy
                        Policy on this page. Contact Us If you have any
                        questions or concerns about our Privacy Policy, please
                        contact us at&nbsp;
                        <a href="mailto:app@ejon.es">app@ejon.es</a>. By using
                        Little Change, you agree to the terms of this Privacy
                        Policy.
                    </p>
                    <div className="link">
                        <Link to="/">Back</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

function NoMatch() {
    return (
        <div className="container">
            <h2 className="logo-text">Nothing to see here!</h2>
            <Link to="/">Go to the home page</Link>
        </div>
    )
}
